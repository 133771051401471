body {
  margin: 0;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

/* remove Papyrus from snackbars */
/* div[class*='SnackbarItem-'] {
  font-family: 'monospaced', sans-serif;
}

#client-snackbar {
  font-family: 'monospaced', sans-serif;
} */

/*
  Force browser to use light-mode.
  NOTE: This does not seem to work (to disable dark mode scroll bars).
  Using perfect-scrolbar should mitigate this issue.
*/
:root {
  color-scheme: light;
}

/* add class to make element unselectable (use sparingly) */
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.sticky * {
  transition: 0.2s all cubic-bezier(0, 0.58, 0.06, 0.94);
}

.MuiDrawer-paper {
  transition: transform 225ms cubic-bezier(0.62, 0.03, 0, 0.99) 0ms;
}

/* supress browser changing input background/shadow when you use auto complete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: all 0s 50000s;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

/* IE/Edge show a "x" button on the right edge of text inputs. Hide them when MUI already provides these */
/* .MuiInput-input::-ms-clear {
  display: none;
} */

.hiddenScrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hiddenScrollbars::-webkit-scrollbar {
  display: none;
}

/* for spinner */

.spinner {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* for status step */

.pulseOpacity {
  animation-name: pulseOpacity;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulseOpacity {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

/* hide placeholder text when input is focussed */
input:focus::placeholder {
  color: transparent;
}

select:disabled {
  opacity: 0.7 !important;
}

.react-datepicker-wrapper {
  font-size: 12px !important;
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
  border: 1.3px solid !important;
}
 */

.googleLoginButton {
  width: 100%;
  box-shadow: none !important;
  border: 1px solid black !important;
  border-radius: 5px !important;
  justify-content: center;
  color: black !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
}

.googleLoginButton > span {
  font-weight: normal !important;
}
.googleLoginButton > div {
  margin-right: 0 !important;
}

.facebookLoginButton {
  width: 260px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
  justify-content: center;
  background-color: white;
  padding: 10px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  /* line-height: 56px; */
}

.location-search:focus {
  outline: none;
}

.pac-container {
  z-index: 10000 !important;
}

/* Transitions */
.rc-scale-in .modal-dialog {
  transform: scale(0.85);
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
  transition-delay: 0;
  opacity: 0;
}
.rc-scale-in.show .modal-dialog {
  opacity: 1;
}

/* When we don't have sass css
  (╯°□°)╯︵ ┻━┻
*/
.modal-overlay {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
  transition-delay: -0.1s;
}
.modal-overlay > * {
  height: 100%;
  width: 100%;
}
/* Enter */
.rc-slide-left-enter .modalBody {
  transform: translateX(110%);
}
.rc-slide-up-enter .modalBody {
  transform: translateY(80vh);
}
.rc-slide-left-enter-active .modalBody,
.rc-slide-up-enter-active .modalBody {
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
  transform: translate(0, 0);
}
.rc-slide-left-enter .modal-overlay *,
.rc-slide-up-enter .modal-overlay * {
  opacity: 0;
}
.rc-slide-left-enter-active .modal-overlay *,
.rc-slide-up-enter-active .modal-overlay * {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
}

/* -> Modal floating button */
.rc-slide-left-enter .modalFloatingButton svg {
  transform: translateX(30vw);
  opacity: 0;
}
.rc-slide-up-enter .modalFloatingButton svg {
  transform: translateY(90vh);
  opacity: 0;
}
.rc-slide-left-enter-active .modalFloatingButton svg,
.rc-slide-up-enter-active .modalFloatingButton svg {
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
  opacity: 1;
  transform: translate(0, 0);
}

/* Exit */
.rc-slide-left-exit .modalBody,
.rc-slide-up-exit .modalBody {
  transform: translate(0, 0);
}
.rc-slide-left-exit-active .modalBody {
  transform: translateX(110%);
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
}
.rc-slide-up-exit-active .modalBody {
  transform: translateY(110%);
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
}

.rc-slide-left-exit .modal-overlay *,
.rc-slide-up-exit .modal-overlay * {
  opacity: 1;
}
.rc-slide-left-exit-active .modal-overlay *,
.rc-slide-up-exit-active .modal-overlay * {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
}

/* -> Modal floating button */
.rc-slide-left-exit .modalFloatingButton svg,
.rc-slide-up-exit .modalFloatingButton svg {
  opacity: 1;
  transform: translate(0, 0);
}
.rc-slide-up-exit-active .modalFloatingButton svg {
  transform: translateY(90vh);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
}
.rc-slide-left-exit-active .modalFloatingButton svg {
  transform: translateX(50vw);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93);
}

/* the worst hack of my career */
.successSnackbar {
  --successSnackbarBgColor: inherit;
  background-color: var(--successSnackbarBgColor) !important;
}

.warningSnackbar {
  --warningSnackbarBgColor: inherit;
  background-color: var(--warningSnackbarBgColor) !important;
}

.errorSnackbar {
  --errorSnackbarBgColor: inherit;
  background-color: var(--errorSnackbarBgColor) !important;
}
